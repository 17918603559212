import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import makeUrl from "../utils/url";

const Hero = ({ children, title, image, link }) => (
  <div
    className="px-4 flex flex-wrap max-w-screen-xl mx-auto relative overflow-hidden"
    style={{ textShadow: "0 0 1rem rgba(0,0,0,0.4)" }}
  >
    <div className=" w-full h-80">
      <GatsbyImage
        className="z-0 h-full w-full rounded-md"
        image={image}
        alt={"hero"}
      />
    </div>
    {!children && <DefaultHero title={title} link={link} />}
    <div className="absolute w-full h-full text-white flex flex-col justify-center items-center">
      {children}
    </div>
  </div>
);

const DefaultHero = ({ title, link }) => (
  <div className="absolute z-10 w-full h-full text-white flex flex-col justify-center items-center p-4">
    <div className="py-8 text-center flex flex-col justify-center items-center">
      <h1 className="h1 inline leading-loose decoration-clone px-2">{title}</h1>
      <h3 className="font-display text-2xl my-6 inline px-1.5">
        Viele neue tolle Geschenkideen!
      </h3>
      <Link to={link ? `/${link}` : `/${makeUrl(title, "anlass")}`}>
        <div className="bg-1 inline-block py-1.5 px-2.5  rounded-full hover:translate-x-1.5 transform transition">
          <span className="flex items-center text-white">Entdecke Ideen</span>
        </div>
      </Link>
    </div>
  </div>
);

export default Hero;
