import React from "react";
import PersonCard from "./PersonCard";
import { graphql, StaticQuery } from "gatsby";

const PersonList = ({ title, items }) => (
  <StaticQuery
    query={graphql`
      query PersonListQuery {
        persons: allContentfulPerson {
          nodes {
            childContentfulPersonTitleTextNode {
              title
            }
            icon {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    `}
    render={(data) => (
      <PersonListContent
        title={title}
        items={items ? items : data.persons.nodes}
      />
    )}
  />
);

const PersonListContent = ({ items, title }) => {
  return (
    <div className="container-xl mt-16 pt-8 text-2xl font-display ">
      <div className="w-full flex mb-3">
        <div className="inline-block ">
          {title ? title : "Die schönsten Ideen für"}
        </div>
      </div>
      <div className="flex items-center bg-1-dark bg-opacity-5 py-8 px-2 lg:px-4 rounded-2xl">
        <div className="grid grid-cols-3 sm:grid-cols-7 md:grid-cols-9 gap-2">
          {items.map((item, i) => (
            <div className="w-full lg:w-24" key={i}>
              <PersonCard {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonList;
