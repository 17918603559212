import React from "react";
import Article from "./Article";
import { graphql, StaticQuery } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FadeIn from "./Motion/FadeIn";
import MoreContent from "./BuildingBlocks/MoreContent";

const ArticleList = ({ event, person }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        let items = data.allContentfulArticle.nodes; //.sort(() => 0.5 - Math.random())

        // filter if person
        if (person)
          items = items.filter((item) =>
            item.persons
              ?.map((itemPerson) => itemPerson.title.title === person)
              .reduce((a, b) => a + b)
          );

        // filter if person
        if (event)
          items = items.filter((item) =>
            item.events
              .map((itemEvent) => itemEvent.title === event)
              .reduce((a, b) => a + b)
          );

        // maximal 5 articles

        let articles = items.map((item, i) => (
          <FadeIn key={i} index={i < 5 ? i : 0}>
            <div className="p-4">
              <Article data={item} index={i} type={"artikel"} />
            </div>
          </FadeIn>
        ));

        if (items.length < 5) {
          [...Array(5 - items.length)].forEach(() => articles.push(<div />));
        }

        const settings = {
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 2,
          arrows: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
              },
            },
          ],
        };

        return (
          <div
            className="mb-8 max-w-screen-xl w-full"
            style={{ maxWidth: "100vw" }}
          >
            <div className="section">Handverlesen & für Dich verfasst.</div>

            <div className="flex flex-col items-center justify-center mx-auto max-w-screen-xl px-4">
              <div className="mb-3 bg-1 bg-opacity-5 w-full rounded-2xl ">
                <div
                  className="max-w-screen-xl mx-auto"
                  style={{ width: "calc(100% - 0rem)" }}
                >
                  <Slider {...settings}>{articles}</Slider>
                </div>
              </div>

              <MoreContent
                text="Alle Artikel"
                path="artikel"
                state={{ event, person }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default ArticleList;

const query = graphql`
  query HighlightQuery {
    allContentfulArticle {
      nodes {
        title
        image {
          file {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [JPG])
            }
          }
        }
        persons {
          title {
            title
          }
        }
        events {
          title
        }
      }
    }
  }
`;
