import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import makeUrl from "../utils/url";

const PersonCard = (props) => {
  const { childContentfulPersonTitleTextNode, icon } = props;
  const title = childContentfulPersonTitleTextNode.title;

  return (
    <Link to={`/${makeUrl(title, "person")}`}>
      <div className="relative h-36">
        <div className="relative z-10 hover:scale-105 transition transform duration-300 w-full h-24 rounded-full overflow-hidden">
          <GatsbyImage
            className="w-full h-full object-cover"
            image={icon.gatsbyImageData}
            alt={title}
          />
        </div>
        <div className="h-24 w-full bg-red transform translate-y-1/2 rounded-3xl absolute top-3 left-3 right-3 z-0" />
        <div className="mt-1.5 text-center relative z-10">
          <h1 className="text-sm sm:text-base text-white">{title}</h1>
        </div>
      </div>
    </Link>
  );
};

export default PersonCard;
