import React from "react";
import { graphql } from "gatsby";
import Hero from "../components/Hero";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetadata";
import IdeaList from "../components/IdeaList/IdeaList";
import EventList from "../components/EventList";
import PersonList from "../components/PersonList";
import ArticleList from "../components/ArticleList";

const IndexPage = ({ data }) => {
  const sortedEvents = data.nextEvent.nodes;
  var nextEvent = sortedEvents[0];
  if (nextEvent.date.endsWith("Monaten"))
    nextEvent = sortedEvents[sortedEvents.length - 1];

  return (
    <Layout>
      <SiteMetadata
        title="Handverlesene Geschenkideen"
        description="Lass dich inspirieren von vielen tollen Geschenkideen für Mama, Papa, Oma und viel mehr!"
      />
      {nextEvent.date}
      <Hero
        nextEvent={nextEvent}
        title={nextEvent.title}
        image={nextEvent.image.localFile.childImageSharp.gatsbyImageData}
      />
      <ArticleList event={nextEvent.title} />
      <PersonList />

      <IdeaList event={nextEvent.title} />

      <EventList items={data.events.nodes} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        date(locale: "de", formatString: "DD. MMMM")
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
    nextEvent: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        title
        date(fromNow: true, locale: "de")
        image {
          file {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
